import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { connctDB } from "../ConnctDB";
import { useLocationContext } from "./LocationInterface";
import { useForm, Controller } from "react-hook-form";
import { LazyLoadImage } from "react-lazy-load-image-component";

const textDataReducer = (data) => {
  return {
    id: data.id,
    color: data.color,
    fontSize: data.fontSize,
    text: data.text,
    fontWeight: data.fontWeight,
    font: data.font,
  };
};

const RazorpayForm = ({
  total,
  cartItems,
  subtotal,
  shippingHandling,
  gst,
}) => {
  const navigate = useNavigate();

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({ mode: "onChange" });

  const [cardsData, setCardsData] = useState([]);

  const { currencySymbol, isIndia, priceType } = useLocationContext();

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      let backTextAndStyle,
        frontTextAndStyle,
        frontImageComponents,
        backImageComponents;

      const filteredCartData = [];

      for (let i = 0; i < cartItems.length; i++) {
        const { cid, ...cartItem } = cartItems[i]; //change back cid to id and remove uuid
        if (!cartItem?.designAssistanceChecked) {
          backTextAndStyle = cartItem?.backTextAndStyle?.map(textDataReducer);
          frontTextAndStyle = cartItem?.frontTextAndStyle?.map(textDataReducer);
          frontImageComponents = cartItem?.frontImageComponents?.map(
            (data) => data.image
          );
          backImageComponents = cartItem?.backImageComponents?.map(
            (data) => data.image
          );
          filteredCartData.push({
            ...cartItem,
            id: cid,
            backTextAndStyle,
            frontTextAndStyle,
            frontImageComponents,
            backImageComponents,
          });
        } else {
          filteredCartData.push({
            ...cartItem,
            id: cid,
          });
        }
      }

      setCardsData(filteredCartData);
    }
  }, [cartItems]);

  useEffect(() => {
    setCountryList(
      Country.getAllCountries()?.map((c) => ({
        isoCode: c.isoCode,
        name: c.name,
      }))
    );
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "country" && type === "change") {
        const countryCode = Country.getAllCountries().find(
          (data) => data.name === value.country
        )?.isoCode;
        setStateList(
          State.getStatesOfCountry(countryCode)?.map((c) => ({
            isoCode: c.isoCode,
            name: c.name,
          }))
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  let notifyStatus;

  const fetchData = async (data) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/login");

    try {
      // Fetch order details
      const currency = priceType === "iprice" ? "USD" : "INR";
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/user/initiate-payment`,
        {
          total: parseInt(total),
          currency: currency,
          products: cardsData,
          referenceId: "",
          address: {
            ...data,
            currency: currency,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      return orderResponse;
    } catch (error) {
      console.error("Error fetching Razorpay order details:", error);
    }
  };

  const onSubmit = async (data) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/login");
    // e.preventDefault();
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.stopPropagation();
    // } else {
    try {
      const res = await fetchData(data);
      const orderDetails = res.data;
      if (!orderDetails) {
        console.error("Order details not available.");
        return;
      }
      const {
        id: orderId,
        amount,
        currency: orderCurrency,
        notes,
      } = orderDetails;

      // Initialize Razorpay
      const paymentCurrency = priceType === "iprice" ? "USD" : "INR";
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: Math.round(amount * 100),
        currency: paymentCurrency,
        order_id: orderDetails.order_id,
        name: "Connct",
        description: "Purchase Description",
        image: "path_to_logo.png",
        handler: async function (response) {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/v1/api/user/confirm-payment`,
            {
              payment_id: response.razorpay_payment_id,
              order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              total: parseInt(total),
              currency: paymentCurrency,
              products: cardsData,
              referenceId: "",
              address: {
                ...data,
                currency: paymentCurrency,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          handleServerResponse(res, data);
        },
        // payment_method: {
        //   netbanking: true,
        //   upi: true,
        //   wallet: true,
        // },
        config: {
          display: {
            blocks: {
              banks: {
                name: "10% Offer",
                instruments: [
                  {
                    method: "upi",
                  },
                  {
                    method: "card",
                  },
                  {
                    method: "wallet",
                  },
                  {
                    method: "netbanking",
                  },
                ],
              },
            },
            sequence: ["block.banks"],
            preferences: {
              show_default_blocks: true,
            },
          },
        },
      };
      console.log(orderDetails.order_id);
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
    }
  };

  const handleServerResponse = async (response, data) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/login");

    if (response.error) {
      // Show error from server on payment form
      toast.error("Payment failed. Please try again.");
    } else if (response.requires_action) {
      // Handle additional action if required
    } else {
      // Show success message
      bulkPurchase(response, data);
    }
  };

  const bulkPurchase = async (response, data) => {
    notifyStatus = toast.loading("Processing");
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/login");

    let country;
    for (const countryData of countryList) {
      if (countryData.isoCode === data.country) country = countryData.name;
    }

    const currency = priceType === "iprice" ? "USD" : "INR";

    const res = await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/user/bulk-purchase-card`,
        {
          ...data,
          country,
          products: cardsData,
          referenceId: response.id,
          total: parseInt(total),
          currency: currency,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .catch(() => window.alert("There was an error!"));

    if (res.status === 200) {
      toast.update(notifyStatus, {
        render: "Completed",
        type: "success",
        closeButton: true,
        isLoading: false,
        autoClose: 1000,
      });
      connctDB.cart.clear();
      navigate("/thank-you");
    }
  };

  return (
    <div>
      <Row>
        {/* left billing */}
        <Col sm={6}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm={6}>
                <Form.Group controlId="firstname">
                  <Form.Label>
                    First Name <span className="asteric">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    {...register("firstname", { required: true })}
                  />
                </Form.Group>
                {errors?.firstname?.type === "required" && (
                  <Form.Text className="text-danger">Required</Form.Text>
                )}
              </Col>
              <Col sm={6}>
                <Form.Group controlId="lastname">
                  <Form.Label>
                    Last Name <span className="asteric">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    {...register("lastname", { required: true })}
                  />
                </Form.Group>
                {errors?.lastname?.type === "required" && (
                  <Form.Text className="text-danger">Required</Form.Text>
                )}
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Form.Group controlId="email">
                  <Form.Label>
                    Email <span className="asteric">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      },
                    })}
                  />
                  {errors?.email?.type === "required" && (
                    <Form.Text className="text-danger">Required</Form.Text>
                  )}
                  {errors?.email?.type === "pattern" && (
                    <Form.Text className="text-danger">
                      Please enter a valid email address
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
  <Form.Group controlId="phonenumber">
    <Form.Label>
      Phone <span className="asteric">*</span>
    </Form.Label>
    <Form.Control
      type="text"
      name="phonenumber"
      {...register("phonenumber", {
        required: true,
        pattern: {
          value: /^(?:\+\d{1,3}\s?)?(?:\(\d{1,4}\)\s?)?\d{1,4}[\s-]?\d{1,4}[\s-]?\d{1,4}$/,
        },
      })}
    />
    {errors?.phonenumber?.type === "required" && (
      <Form.Text className="text-danger">Required</Form.Text>
    )}
    {errors?.phonenumber?.type === "pattern" && (
      <Form.Text className="text-danger">
        Please enter a valid phone number
      </Form.Text>
    )}
  </Form.Group>
</Col>

            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Form.Group controlId="company">
                  <Form.Label>
                    Company <span className="asteric">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    {...register("company", { required: true })}
                  />
                </Form.Group>
                {errors?.company?.type === "required" && (
                  <Form.Text className="text-danger">Required</Form.Text>
                )}
              </Col>
              <Col sm={6}>
                <Form.Group controlId="street">
                  <Form.Label>
                    Street Address <span className="asteric">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    {...register("street", { required: true })}
                  />
                </Form.Group>
                {errors?.street?.type === "required" && (
                  <Form.Text className="text-danger">Required</Form.Text>
                )}
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Form.Group controlId="country">
                  <Form.Label>
                    Country <span className="asteric">*</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="country"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Form.Control
                          as="select"
                          name="country"
                          value={value}
                          onChange={onChange}
                          style={{ padding: "5px 10px", marginBottom: "0" }}
                        >
                          <option value="">Select a country</option>
                          {countryList?.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Form.Control>
                      );
                    }}
                  />
                  {errors?.country?.type === "required" && (
                    <Form.Text className="text-danger">Required</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="state">
                  <Form.Label>
                    State <span className="asteric">*</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="state"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Form.Control
                          as="select"
                          name="state"
                          value={value}
                          onChange={onChange}
                          style={{ padding: "5px 10px", marginBottom: "0" }}
                        >
                          {stateList?.map((state, index) => (
                            <option key={index} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </Form.Control>
                      );
                    }}
                  />
                  {errors?.state?.type === "required" && (
                    <Form.Text className="text-danger">Required</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Form.Group controlId="zip">
                  <Form.Label>
                    Postcode <span className="asteric">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zip"
                    {...register("zip", { required: true })}
                  />
                </Form.Group>
                {errors?.zip?.type === "required" && (
                  <Form.Text className="text-danger">Required</Form.Text>
                )}
              </Col>
              <Col sm={6}>
                <Form.Group controlId="city">
                  <Form.Label>
                    Town & City <span className="asteric">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    {...register("city", { required: true })}
                  />
                </Form.Group>
                {errors?.city?.type === "required" && (
                  <Form.Text className="text-danger">Required</Form.Text>
                )}
              </Col>
            </Row>
            <br />
            <Form.Group controlId="notes">
              <Form.Label>Order Note</Form.Label>
              <Form.Control as="textarea" name="notes" {...register("notes")} />
            </Form.Group>
          </Form>
        </Col>
        {cartItems?.length > 0 && (
          <Col md={6}>
            <div className="order-details">
              <h6 style={{ textAlign: "center", fontWeight: 700 }}>
                Your Order
              </h6>
              <div>
                {cartItems?.map((item, index) => (
                  <Row className="cart-type" style={{ marginRight: "0" }}>
                    <Col sm={2} md={2}>
                      <LazyLoadImage
                        src={`https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${item.image}`}
                        // src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                        alt={item.name}
                        style={{
                          maxWidth: "60px",
                          maxHeight: "60px",
                          padding: 0,
                        }}
                      />
                    </Col>
                    <Col sm={8} md={8}>
                      <div className="card-text">{item.name}</div>
                      <div>Quantity: {item.addedQuantity}</div>
                    </Col>

                    <Col sm={3} md={2} className="card-text">
                    {currencySymbol} {item[priceType]}
                    </Col>
                  </Row>
                ))}
              </div>
              <div className="billing-row">
                <p>Sub Total</p>
                <p>
                  {currencySymbol} {subtotal?.toFixed(2)}
                </p>
              </div>
              <div className="billing-row">
                <p>Shipping and Handling</p>
                <p>
                  {currencySymbol} {shippingHandling?.toFixed(2)}
                </p>
              </div>
              <div className="billing-row" style={{ border: "none" }}>
                <p>{isIndia ? "GST" : "VAT"}</p>
                <p>
                  {currencySymbol} {gst?.toFixed(2)}
                </p>
              </div>
              <div className="billing-row">
                <p className="card-text">Total</p>
                <p className="card-text">
                  {currencySymbol} {total?.toFixed(2)}
                </p>
              </div>
            </div>
            <div className="mt-1"></div>
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="w-100 btn btn-warning"
            >
              Place your order
            </button>
            <div className="d-flex">
              <div className="col-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  fill="currentColor"
                  class="bi bi-shield-lock-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm0 5a1.5 1.5 0 0 1 .5 2.915l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99A1.5 1.5 0 0 1 8 5z"
                  />
                </svg>
              </div>
              <div className="col-11">
                <p style={{ fontWeight: "bolder" }}>
                  All your data are safe. We are using the most advanced
                  security to provide you the best experience ever.
                </p>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RazorpayForm;
