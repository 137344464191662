import React from "react";
import "./Downloadconnct.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Downloadconnct = () => {
  return (
    <div className="wrapper" style={{ marginTop: "130px" }}>
      <div className="container section text-center">
        <h3 style={{ fontSize: "29px", fontWeight: "bold" }}>
          Scan with your phone to download Connct
        </h3>
        <div className="scan-block mt-4">
          <LazyLoadImage
            src={require("../assets/images/scan_to_downoad_app.png")}
            alt=""
          />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Downloadconnct;
