import React, { useState } from "react";
import "./Footer.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Form, Button, Alert } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailValue, setEmailValue] = useState(""); // State for email input value

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/public-api/subscribe-newsletter`,
        data
      );
      setSuccessMessage(
        "Congratulations You are now Subscribed for our Newsletter"
      );
      setErrorMessage("");
      setEmailValue("");

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred");
      setSuccessMessage("");
      setEmailValue("");

      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmailValue(e.target.value); // Update email input value in state
  };
  return (
    <footer>
      <div className="container pt-5 mt-5">
        <div className="row pb-3 justify-content-evenly">
          <div className="col-md-4">
            <a href="/" className="router-link">
              <LazyLoadImage
                className="footer-logo"
                src={require("../assets/images/c.png")}
                alt="footer-logo"
                style={{ width: "43%" }}
              />
            </a>
            <p style={{ marginTop: "10px", fontSize: "16px" }}>
              Ace Modern Networking
            </p>
            <div className="social-media-links">
              <a href="https://www.linkedin.com/company/connct/">
                <LazyLoadImage
                  src={require("../assets/images/linkedin.png")}
                  alt="linkedin"
                />
              </a>
              <a href="https://www.facebook.com/connctcard">
                <LazyLoadImage
                  src={require("../assets/images/fb.png")}
                  alt="facebook"
                />
              </a>
              <a href="https://www.instagram.com/connct.ai/">
                <LazyLoadImage
                  src={require("../assets/images/insta.png")}
                  alt="instagram"
                />
              </a>
              <a href="https://twitter.com/connct_ai">
                <LazyLoadImage
                  src={require("../assets/images/twitter.png")}
                  alt="twitter"
                />
              </a>
            </div>
          </div>
          <div className="col-md-2 col-6 my-2 my-md-0">
            <h6 style={{ fontWeight: "800" }}>info.</h6>
            <ul>
              <li>
                <a href="/terms" style={{ fontSize: "16px" }}>
                  Terms & Condition
                </a>
              </li>
              <li>
                <a href="/privacy-policy" style={{ fontSize: "16px" }}>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/refund-policy" style={{ fontSize: "16px" }}>
                  Refund Policy
                </a>
              </li>
              <li>
                <a href="/shipping-policy" style={{ fontSize: "16px" }}>
                  Shipping Policy
                </a>
              </li>
              <li>
                <a href="/faq" style={{ fontSize: "16px" }}>
                  FAQ
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-6 my-2 my-md-0">
            <h6 style={{ fontWeight: "800" }}>Connct.</h6>
            <ul>
              <li>
                <a href="/compatible-devices" style={{ fontSize: "16px" }}>
                  Compatible Devices
                </a>
              </li>
              <li>
                <a href="/upgrade-to-pro" style={{ fontSize: "16px" }}>
                  Pro
                </a>
              </li>
              <li>
                <a href="/ambassador" style={{ fontSize: "15px" }}>
                  Become an Ambassador
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-4 newsletter">
            <h6 style={{ fontWeight: "700" }}>Join newsletter</h6>
            <p style={{ fontSize: "14px" }}>
              Subscribe to our newsletter to get free design courses and
              resources
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="email" className="input-with-icon">
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email"
                    isInvalid={!!errors.email}
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                    })}
                    value={emailValue} // Set value from state
                    onChange={handleEmailChange} // Handle input change and update state
                    style={{
                      paddingRight: "60px",
                      marginRight: "90px",
                      marginBottom: "10px",
                    }} // Increase the right padding to make more space for the button
                  />
                  <Button
                    type="submit"
                    variant="primaruy"
                    disabled={loading || isSubmitting}
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      border: "1px solid black",
                      position: "absolute",
                      right: "10px",
                      top: "5px",
                      bottom: "0",
                      height: "30px",
                      width: "30px",
                      display: "flex", // Ensures flex alignment properties work
                      justifyContent: "center", // Horizontally centers content
                      alignItems: "center", // Vertically centers content
                    }}
                  >
                    <FaArrowRight
                      style={{ marginLeft: "-15px", marginRight: "-15px" }}
                    />{" "}
                    {/* Adjust marginLeft to move the icon */}
                  </Button>
                </div>
              </Form.Group>

              <Form.Control.Feedback type="invalid">
                {errors.email &&
                  errors.email.type === "required" &&
                  "Email address is required"}
                {errors.email &&
                  errors.email.type !== "required" &&
                  "Please enter a valid email address"}
              </Form.Control.Feedback>
            </Form>
            {successMessage && (
              <Alert variant="success">{successMessage}</Alert>
            )}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          </div>
        </div>
        <div className="row pb-4 pt-4 copy">
          <div className="col-md-6">Copyright © 2022. All rights reserved</div>
          <div className="col-md-6 cookies">
            We use cookies for better service.
            {/* <a href="#" className="text-warning">accept</a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
