import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import "./Shoppingcart.css";
import { useNavigate } from "react-router-dom";
import { connctDB } from "../ConnctDB";
import { useLiveQuery } from "dexie-react-hooks";
import UpdateCustomization from "./UpdateCustomization";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiFillEdit } from "react-icons/ai";
import { useLocationContext } from "./LocationInterface";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ShoppingCart = () => {
  const navigate = useNavigate();
  const [cartCardShow, setCartCardShow] = useState({ state: false, index: 0 });
  const [customizeModalShow, setCustomizeModalShow] = useState(false);

  const cartItems = useLiveQuery(() => connctDB.cart.orderBy("cid").toArray());
  const [data, setData] = useState({});

  const { currencySymbol, priceType, isIndia } = useLocationContext();

  // Calculate subtotal, shipping, GST, and total
  const subtotal = cartItems?.reduce((acc, item) => {
    return acc + item[priceType] * item.addedQuantity;
  }, 0);

  const shippingHandling = 0; // Set the shipping and handling fee to 0
  const gstPerProduct = isIndia ? 359.82 : 4.31; // Fixed GST amount for a single product
  const totalQty = cartItems?.reduce(
    (acc, item) => acc + item.addedQuantity,
    0
  ); // Total quantity of all products in the cart
  const gst = gstPerProduct * totalQty; // Calculate GST based on the total quantity
  const total = subtotal + shippingHandling + gst;

  return (
    <Container style={{ marginTop: "120px" }} className="cart-container">
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          // gap: "10px",
          margin: 0,
          padding: 0,
        }}
      >
        <Col xs={10} md={8} className="cart-items" style={{ padding: 0 }}>
          {cartItems?.map((item, index) => (
            <Row
              className="cart-type"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
              key={index}
            >
              <Col xs={4} sm={2} md={2}>
                <LazyLoadImage
                  src={`https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${item.image}`}
                  alt={item.name}
                  style={{ maxWidth: "60px", maxHeight: "60px" }}
                />
                {cartCardShow.state === true &&
                  cartCardShow.index === index &&
                  item?.frontImage && (
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <LazyLoadImage
                            src={item?.frontImage}
                            alt={item.name}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                        <div className="flip-card-back">
                          <LazyLoadImage
                            src={item?.backImage}
                            alt={item.name}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </Col>
              <Col
                xs={
                  cartCardShow.state === true && cartCardShow.index === index
                    ? 12
                    : 8
                }
                sm={
                  cartCardShow.state === true && cartCardShow.index === index
                    ? 6
                    : 4
                }
                md={
                  cartCardShow.state === true && cartCardShow.index === index
                    ? 6
                    : 4
                }
              >
                <div className="card-text">{item.name}</div>
                <div>Quantity: {item.addedQuantity}</div>
                {item?.slug?.split("-")[0] === "custom" &&
                  (item?.designAssistanceChecked ? (
                    <div>Need Design Assistance</div>
                  ) : (
                    <div>Don't Need Design Assistance</div>
                  ))}
                {cartCardShow.state && cartCardShow.index === index && (
                  <div style={{ marginTop: "0" }}>
                    <div style={{ fontSize: "14px", marginBottom: "2px" }}>
                      Primary Text:&nbsp;
                      <span style={{ fontWeight: 700 }}>
                        {item?.frontTextAndStyle &&
                          item?.frontTextAndStyle[0].text}
                      </span>
                    </div>
                    <div style={{ fontSize: "14px", marginBottom: "2px" }}>
                      Secondary Text:&nbsp;
                      <span style={{ fontWeight: 700 }}>
                        {item?.frontTextAndStyle &&
                          item?.frontTextAndStyle[1]?.text}
                      </span>
                    </div>
                    <div style={{ fontSize: "14px", marginBottom: "2px" }}>
                      Delivery Estimation:
                      <span style={{ fontWeight: 700 }}>5-7 days</span>
                    </div>
                  </div>
                )}
              </Col>
              {cartCardShow.state && cartCardShow.index === index ? (
                <Col xs={3} sm={2} md={2}>
                  <button
                    onClick={() => {
                      // setCartCardShow(false);
                      setCustomizeModalShow(true);
                      setData(item);
                    }}
                    style={{
                      fontWeight: 700,
                      fontSize: "12px",
                      padding: "4px 8px",
                      border: "1px solid #C0C0C0",
                      borderRadius: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    disabled={item?.designAssistanceChecked}
                  >
                    <AiFillEdit size={"12px"} cursor={"pointer"} />
                    &nbsp;Edit
                  </button>
                </Col>
              ) : (
                <>
                  <Col xs={3} sm={2} md={2}>
                    <input
                      type="number"
                      value={item.addedQuantity}
                      onChange={(e) => {
                        connctDB.cart.put({
                          ...item,
                          addedQuantity: parseInt(e.target.value),
                        });
                      }}
                      min="1"
                      className="qty-input card-input"
                      disabled={!item?.designAssistanceChecked}
                    />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="card-text">
                    {currencySymbol} {item[priceType]}
                  </Col>
                </>
              )}
              <Col xs={2} sm={1} md={1}>
                <div
                  onClick={() => {
                    setCartCardShow({
                      state:
                        cartCardShow.index === index
                          ? !cartCardShow.state
                          : true,
                      index: index,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {cartCardShow.index === index &&
                  cartCardShow.state === true ? (
                    <IoIosArrowUp size={"15px"} cursor={"pointer"} />
                  ) : (
                    <IoIosArrowDown size={"15px"} cursor={"pointer"} />
                  )}
                </div>
              </Col>
              <Col xs={2} sm={1} md={1}>
                <div
                  onClick={() => {
                    // const arr = [...cartItems];
                    // arr.splice(index, 1);
                    // localStorage.setItem("cart", JSON.stringify(arr));
                    // window.dispatchEvent(new Event("storage"));
                    // setCartItems(arr);
                    connctDB.cart.delete(item?.id);
                  }}
                >
                  <IoMdRemoveCircleOutline
                    color={"red"}
                    size={"15px"}
                    cursor={"pointer"}
                  />
                </div>
              </Col>
            </Row>
          ))}
          <UpdateCustomization
            show={customizeModalShow}
            onHide={() => {
              setCustomizeModalShow(false);
              setCartCardShow(true);
            }}
            data={data}
            key={data.cid}
          />
        </Col>
        {cartItems?.length > 0 && (
          <Col xs={10} md={4}>
            <div className="cart-total">
              <div className="rows">
                <h6 style={{ textAlign: "center" }}>Cart</h6>
                <div className="total-row">
                  <p>Sub Total</p>
                  <p>
                    {currencySymbol} {subtotal?.toFixed(2)}
                  </p>
                </div>
                <div className="total-row">
                  <p>Shipping and Handling</p>
                  <p>
                    {currencySymbol} {shippingHandling?.toFixed(2)}
                  </p>
                </div>
                <div className="total-row">
                  <p>{isIndia ? "GST" : "VAT"}</p>
                  <p>
                    {currencySymbol} {gst?.toFixed(2)}
                  </p>
                </div>
                <div className="total-row">
                  <p className="card-text">Total</p>
                  <p className="card-text">
                    {currencySymbol} {total?.toFixed(2)}
                  </p>
                </div>
                <div>
                  <Button
                    variant="primary"
                    style={{ width: "100%", marginTop: "10px" }}
                    onClick={(e) => {
                      console.log("checkout");
                      const auth = localStorage.getItem("authToken") || null;
                      if (!auth)
                        navigate("/login", { state: { fromCart: true } });
                      else navigate("/billing");
                    }}
                  >
                    Checkout
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Button
        variant="outline-primary"
        style={{ marginTop: "10px" }}
        onClick={(e) => {
          navigate("/product-list");
        }}
      >
        Back To Shopping
      </Button>
    </Container>
  );
};

export default ShoppingCart;
