import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Signup = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const verticalLineStyle = {
    borderLeft: "2px solid lightgray",
    height: "80%",
    position: "absolute",
    left: "-10px",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage(""); // Reset previous error message
    setSuccessMessage(""); // Reset previous success message

    // notifyStatus = toast.loading("Processing...", {
    //   closeButton: "false",
    //   isLoading: "true",
    // });

    // Validate email, password, and confirm password
    if (!email) {
      setEmailError("Email is required");
      return;
    } else {
      setEmailError("");
    }
    if (!password) {
      setPasswordError("Password is required");
      return;
    } else {
      setPasswordError("");
    }

    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      return;
    } else {
      setConfirmPasswordError("");
    }

    if (password !== confirmPassword) {
      setPasswordMatchError("Passwords do not match");
      return;
    } else {
      setPasswordMatchError("");
    }

    try {
      // Make API call to sign up user
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/auth/signup`,
        {
          email: email,
          password: password,
        }
      );

      // Handle success response
      setSuccessMessage("Signup successful!");

      // Clear input fields and error messages
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setEmailError("");
      setPasswordError("");
      setConfirmPasswordError("");
      setPasswordMatchError("");

      console.log("Signup successful!", response.data);
      response.status === 200 &&
        // toast.update(notifyStatus, {
        //   render: "Signup successful!",
        //   type: "success",
        //   closeButton: true,
        //   isLoading: false,
        //   autoClose: 1000,
        // });
        toast.success("Signup successful!");
      response.status === 200 &&
        navigate("/login", { state: { fromSignup: true } });
    } catch (error) {
      // Handle error response
      if (error.response && error.response.data && error.response.data.error) {
        // toast.update(notifyStatus, {
        //   render: error?.response?.data?.error,
        //   type: "error",
        //   closeButton: true,
        //   isLoading: false,
        //   autoClose: 1000,
        // });
        toast.error(error?.response?.data?.error);
        setErrorMessage(error.response.data.error);
      } else {
        // toast.update(notifyStatus, {
        //   render: "Signup failed. Please try again later.",
        //   type: "error",
        //   closeButton: true,
        //   isLoading: false,
        //   autoClose: 1000,
        // });
        toast.error("Signup failed. Please try again later.");
        // setErrorMessage("Signup failed. Please try again later.");
        setErrorMessage(
          "Email Id already exist, Please use different Email Id."
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
      // toast.update(notifyStatus, {
      //   render: "Signup failed.",
      //   type: "error",
      //   closeButton: true,
      //   isLoading: false,
      //   autoClose: 1000,
      // });
      // toast.error("Signup failed.");
      console.error("Signup failed:", error);
    }
  };

  return (
    <div className="wrapper" style={{ marginTop: "120px" }}>
      <nav className="navbar navbar-expand-lg fixed">
        <div className="container">
          <a className="navbar-brand" href="/">
            <LazyLoadImage
              className="header-logo img-fluid"
              src={require("../assets/images/logos.png")}
              alt="Logo"
              style={{ maxWidth: "150px" }}
            />
          </a>
          <button
            className={`navbar-toggler ${isMenuOpen ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/faq"
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    fontWeight: "normal", // Add this line
                  }}
                >
                  {!isMenuOpen && <span style={verticalLineStyle}></span>}
                  Need any help?
                </a>
              </li>
              {/* Add more menu items here */}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container">
        <section className="login">
          <div className="row align-items-center">
            <div className="col-sm-6 login-left d-none d-sm-block">
              <LazyLoadImage
                src={require("../assets/images/login.jpg")}
                className="img-fluid d-block mx-auto login-image"
                alt="Login"
                style={{ height: "500px" }}
              />
            </div>

            <div className="col-sm-6 login-right">
              <div className="login-account">
                <a href="/">
                  <LazyLoadImage
                    src={require("../assets/images/logo-icon.png")}
                    className="footer-logo d-block mx-auto mt-5"
                    alt="Logo Icon"
                    style={{ maxHeight: "100%", width: "auto" }}
                  />
                </a>
                <h2>Welcome </h2>
                <h3>Create a new account</h3>
                {/* Uncomment this section if you want to include the error alert */}
                {/* <div className="alert alert-danger p-2 small" role="alert">
                  Fields cannot be empty.
                </div> */}
                <form onSubmit={handleSubmit}>
                  {errorMessage && (
                    <div className="alert alert-danger">{errorMessage}</div>
                  )}
                  {successMessage && (
                    <div className="alert alert-success">{successMessage}</div>
                  )}
                  <div className="mb-3">
                    <input
                      id="email"
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && (
                      <span className="text-danger">{emailError}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <input
                      id="password"
                      type="password"
                      className="form-control in2"
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordError && (
                      <span className="text-danger">{passwordError}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <input
                      id="confirmPassword"
                      type="password"
                      className="form-control in2"
                      placeholder="Confirm Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {confirmPasswordError && (
                      <span className="text-danger">
                        {confirmPasswordError}
                      </span>
                    )}

                    {passwordMatchError && (
                      <span className="text-danger">{passwordMatchError}</span>
                    )}
                  </div>
                  <button
                    id="login-button"
                    className="btn btn-primary"
                    type="submit"
                  >
                    Sign Up
                  </button>
                  <p className="d-flex acc">Already have an acccount? </p>
                  <p className="d-flex signup">
                    <a className="black fw-bold" href="/login">
                      Login/Signup with Gmail or Facebook
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Signup;
