import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { isTokenValid } from "../Components/Auth";
import { toast } from "react-toastify";
import { connctDB } from "../ConnctDB";
import { useLiveQuery } from "dexie-react-hooks";
import allProducts from "../assets/images/Ap.jpg";
import businessCards from "../assets/images/bc.jpg";
import customCards from "../assets/images/cc.jpg";
import pro from "../assets/images/pro.png";
import enterprise from "../assets/images/enterprise.png";
import faq from "../assets/images/faq.png";
import contact from "../assets/images/contact.png";
import blogs from "../assets/images/blogs.png";
import basicCards from "../assets/images/basicc.jpg";
import recycleCard from "../assets/images/rc.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [windowWidth, setWindowWidth] = useState();

  const navigate = useNavigate();

  const cartItemLength = useLiveQuery(() => connctDB.cart.count());

  const handleSignOutClick = () => {
    // Perform logout actions
    localStorage.removeItem("authToken");
    localStorage.removeItem("authUser");
    setAuthUser(null);

    toast.success("Signed out successfully!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });

    console.log("logout");
    navigate("/blogs");
    setIsLoggedIn(false);

    // Close the dropdown menu
    setShowDropDown(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    const authUser = localStorage.getItem("authUser");
    setAuthUser(authUser);
    const isValidToken = isTokenValid(storedToken);

    if (isValidToken) {
      setIsLoggedIn(true);
    } else {
      // Token is expired or invalid, log the user out
      localStorage.removeItem("authToken");
      localStorage.removeItem("authUser");
      setAuthUser(null);
      setIsLoggedIn(false);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return window.addEventListener("resize", () =>
      setWindowWidth(window.innerWidth)
    );
  }, []);

  return (
    <>
      {windowWidth > 992 ? (
        <div
          className={
            showDropDown ? "discover-dropdown show" : "discover-dropdown"
          }
          key="1"
          onMouseLeave={() => setShowDropDown(false)}
        >
          <div className="container">
            <div className="left-container">
              <Link to="/product-list">
                <LazyLoadImage
                  className="img-fluid"
                  src={allProducts}
                  alt="allProducts"
                  style={{ borderRadius: "10px" }}
                />
              </Link>
              <div className="line"></div>
              <Link to="/product-list">
                <LazyLoadImage
                  className="img-fluid"
                  src={businessCards}
                  alt="businessCard"
                  style={{ borderRadius: "10px" }}
                />
              </Link>
              <div>
                <Link to="/product-list" state={{ dest: "basicCards" }}>
                  <LazyLoadImage
                    className="img-fluid"
                    src={basicCards}
                    alt="basicCard"
                    style={{ borderRadius: "10px" }}
                  />
                </Link>
                <Link to="/product-list" state={{ dest: "customCards" }}>
                  <LazyLoadImage
                    className="img-fluid"
                    src={customCards}
                    alt="customCard"
                    style={{ marginTop: "5px", borderRadius: "10px" }}
                  />
                </Link>
              </div>
            </div>
            <div className="recycleCard">
              <LazyLoadImage
                className="img-fluid"
                src={recycleCard}
                alt="recycleCard"
                style={{ borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          key="2"
          className={showDropDown ? "nav-dropdown show" : "nav-dropdown"}
        >
          <div className="container">
            <div className="large-cards">
              <Link to="/product-list" onClick={() => setShowDropDown(false)}>
                <LazyLoadImage src={allProducts} alt="allProducts" />
              </Link>
              <div className="line"></div>
              <Link to="/product-list" onClick={() => setShowDropDown(false)}>
                <LazyLoadImage src={businessCards} alt="businessCard" />
              </Link>
            </div>
            <div className="small-cards" onClick={() => setShowDropDown(false)}>
              <Link to="/product-list" state={{ dest: "basicCards" }}>
                <LazyLoadImage src={basicCards} alt="basicCard" />
              </Link>
              <Link
                to="/product-list"
                state={{ dest: "customCards" }}
                onClick={() => setShowDropDown(false)}
              >
                <LazyLoadImage src={customCards} alt="customCard" />
              </Link>
              <Link to="/upgrade-to-pro" onClick={() => setShowDropDown(false)}>
                <LazyLoadImage src={pro} alt="pro" />
              </Link>
              <Link to="/enterprise" onClick={() => setShowDropDown(false)}>
                <LazyLoadImage src={enterprise} alt="enterprise" />
              </Link>
              <Link to="/faq" onClick={() => setShowDropDown(false)}>
                <LazyLoadImage src={faq} alt="faq" />
              </Link>
              <Link to="/contact" onClick={() => setShowDropDown(false)}>
                <LazyLoadImage src={contact} alt="contact" />
              </Link>
              <Link to="/blogs" onClick={() => setShowDropDown(false)}>
                <LazyLoadImage src={blogs} alt="blogs" />
              </Link>
            </div>
            <div className="user">
              {isLoggedIn ? (
                <>
                  <li className="item user">
                    <div className="user-info">
                      <i
                        className="fa fa-user-circle"
                        aria-hidden="true"
                        style={{ fontSize: "29px", color: "#6E83B7" }}
                      ></i>
                      <div
                        className="user-options"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <Link to="#" onClick={handleSignOutClick}>
                          Sign Out
                        </Link>
                        <br />
                        <Link
                          to="/forgot-password"
                          onClick={() => setShowDropDown(false)}
                        >
                          Forgot Password
                        </Link>
                      </div>
                    </div>
                    <div className="auth-user">{authUser}</div>
                  </li>
                </>
              ) : (
                <div className="item login" id="login">
                  <Link
                    to="/login"
                    className="login-link"
                    onClick={() => setShowDropDown(false)}
                  >
                    Login
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <nav className="nav fixed">
        <div className="container">
          <div className="left-nav">
            <a className="navbar-brand" href="/">
              <LazyLoadImage
                src={require("../assets/images/c.png")}
                alt="Logo"
                className="logo-img"
                style={{ height: "28px" }}
              />
            </a>
            <ul className="navbar-main-nav" style={{ padding: 0, margin: 0 }}>
              <li className="item dropdown">
                <div
                  className="navbar-link"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => setShowDropDown(true)}
                  onClick={() => setShowDropDown((prev) => !prev)}
                >
                  Discover
                </div>
              </li>
              <li className="item">
                <a
                  className={`navbar-link ${
                    window.location.pathname === "/upgrade-to-pro"
                      ? "active"
                      : ""
                  }`}
                  href="/upgrade-to-pro"
                >
                  <div
                    style={{
                      backgroundColor: "#fadb86",
                      borderRadius: "7px",
                      color: "white",
                      padding: "1px 8px",
                      display: "inline-block",
                      textDecoration: "none",
                      fontSize: "11px",
                    }}
                  >
                    Pro
                  </div>
                </a>
              </li>

              <li className="item">
                <a
                  className={`navbar-link ${
                    window.location.pathname === "/enterprise" ? "active" : ""
                  }`}
                  href="/enterprise"
                >
                  Enterprise
                  {window.location.pathname === "/enterprise" && <span></span>}
                </a>
              </li>

              <li className="item">
                <a
                  className={`navbar-link ${
                    window.location.pathname === "/faq" ? "active" : ""
                  }`}
                  href="/faq"
                >
                  FAQ
                </a>
              </li>

              <li className="item">
                <a
                  className={`navbar-link ${
                    window.location.pathname === "/contact" ? "active" : ""
                  }`}
                  href="/contact"
                >
                  Contact
                </a>
              </li>

              <li className="item">
                <a
                  className={`navbar-link ${
                    window.location.pathname === "/blogs" ? "active" : ""
                  }`}
                  href="/blogs"
                >
                  Blogs
                </a>
              </li>
            </ul>
          </div>
          <ul className="right-nav" style={{ padding: 0, margin: 0 }}>
            <li className="item" id="cart">
              <Link to="/shoppingcart" onClick={() => setShowDropDown(false)}>
                <LazyLoadImage
                  src={require("../assets/images/cart.png")}
                  alt="Cart"
                />
                {cartItemLength > 0 && (
                  <span className="badge">{cartItemLength}</span>
                )}
              </Link>
            </li>

            <li className="item ham-item">
              <button
                className="navbar-toggler"
                type="button"
                onClick={() => setShowDropDown((prev) => !prev)}
                aria-expanded={showDropDown}
                aria-controls="navbarSupportedContent"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </li>
            {isLoggedIn ? (
              <>
                <li className="item user">
                  <div className="user-info">
                    <i
                      className="fa fa-user-circle"
                      aria-hidden="true"
                      style={{ fontSize: "29px", color: "#6E83B7" }}
                    ></i>
                    <div
                      className="user-options"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <Link to="#" onClick={handleSignOutClick}>
                        Sign Out
                      </Link>
                      <br />
                      <Link to="/forgot-password">Forgot Password</Link>
                    </div>
                  </div>
                  <div className="auth-user">{authUser}</div>
                </li>
              </>
            ) : (
              <li className="item login" id="login">
                <Link to="/login" className="login-link">
                  Login
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
