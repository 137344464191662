import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Pagenotfound = () => {
  return (
    <div className="container thank">
      <div className="mt-5 mb-5">
        <p className="text-small fw-bold">
          <b>
            <a className="grey" href="/">
              Home
            </a>
            <span> / Order Success</span>
          </b>
        </p>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-md-12 text-center thanks">
            <LazyLoadImage
              src={require("../assets/images/logo-icon.png")}
              alt="Logo"
              className="thank-logo"
            />
            <h3 className="title mt-4">404 - Not Found</h3>
            <p className="">The page you are looking for does not exist.</p>
            <a className="btn btn-primary" href="/product-list">
              continue shopping
            </a>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Pagenotfound;
