import React, { useState } from "react";
import "./Faq.css";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Faq = () => {
  const [activeTab, setActiveTab] = useState("home");
  return (
    <div>
      <Helmet>
        <title>
          Digital Business Card FAQ: Answers to Your Burning Questions
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="facebook-domain-verification"
          content="76mxtqr8maf4jd7publr59pn5stjur"
        ></meta>
        <meta
          name="description"
          content="Discover everything you need to know about digital business cards in our comprehensive FAQ session. Get answers &amp; learn more!"
        ></meta>
        <meta
          name="title"
          content="Contact the Best Digital Business Card Provider | Connct"
        ></meta>
        <meta
          name="description"
          content="Contact Connct for effortless digital networking. Elevate connections with NFC-enabled business cards. Tap and go for seamless networking!"
        ></meta>
      </Helmet>
      <div class="wrapper" style={{ marginTop: "156px" }}>
        <section class="section faq">
          <div class="container">
            <div class="page-header">
              <h1 style={{ fontWeight: "700", fontSize: "32px" }}>
                Frequently Asked Questions
              </h1>
            </div>
            <br />
            <hr className="lightgray-line" />
            <section className="faq-content">
              <div className="row">
                <div class="col-lg-9">
                  <ul
                    class="nav nav-tabs"
                    id="myTab"
                    role="tablist"
                    style={{ marginTop: "4px" }}
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        General
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Card
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        App
                      </button>
                    </li>
                  </ul>

                  <div class="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "home" ? "show active" : ""
                      }`}
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              style={{ fontSize: "18px" }}
                            >
                              HOW DOES CONNCT WORK?
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                The Connct card has an NFC chip inside that
                                wirelessly sends your information. If you tap
                                the card on compatible phones, a link will open
                                up with all your information. Ready to be saved
                                to the phone. For older phones that don't have
                                NFC, there is a QR code on the back of your
                                digital card.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              style={{ fontSize: "18px" }}
                            >
                              DO I NEED TO PAY A MONTHLY FEE?
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                You do not require a monthly fee. Just a
                                one-time payment for the digital business card.
                                However, the upgrade to advanced features
                                requires a subscription fee which is completely
                                optional. You can check prices for all the NFC
                                cards here
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              style={{ fontSize: "18px" }}
                            >
                              HOW LONG WILL MY ORDER TAKE TO SHIP?
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                It will take 3-5 business days after the order
                                is placed.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade ${
                        activeTab === "profile" ? "show active" : ""
                      }`}
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              style={{ fontSize: "18px" }}
                            >
                              DOES THE OTHER PERSON NEED THE CARD OR AN APP FOR
                              YOU TO SHARE INFORMATION?
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                No, The other person doesn’t have to have the
                                card or the app. They just need a phone
                                compatible with Connct, which most phones
                                usually are. But in the rare case they don’t,
                                just share your QR code to send all your
                                important info via your Connct profile.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              style={{ fontSize: "18px" }}
                            >
                              WHAT PHONES ARE COMPATIBLE WITH THE ELECTRONIC
                              BUSINESS CARD?
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                Almost all the latest android phones & iPhones
                                after 7 are compatible with Connct. But you can
                                check the list of devices{" "}
                                <a href="/compatible-devices"> here</a>, just to
                                be sure.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              style={{ fontSize: "18px" }}
                            >
                              CAN I GET A CUSTOM BUSINESS CARD DESIGN FOR MY
                              COMPANY?
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                Absolutely ! We have an in-house designer that
                                will take care of all the customization for your
                                Connct card as per your brand guidelines. You
                                can check our range of custom cards{" "}
                                <a href="/product-list">here</a>.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFour">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapsefour"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              style={{ fontSize: "18px" }}
                            >
                              HOW DO I ACTIVATE MY DIGITAL CARD?
                            </button>
                          </h2>
                          <div
                            id="collapsefour"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <ul>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Go to Settings.{" "}
                                </li>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Click on “Activate account”.
                                </li>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Enter the activation key [ You will receive it
                                  in your email / you will get the activation
                                  key with your Connct Card].{" "}
                                </li>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Now to go “Link card” , Click on “Link Now” ,
                                  Place your Connct card behind the phone till
                                  you get the success message.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "contact" ? "show active" : ""
                      }`}
                      id="contact"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              style={{ fontSize: "18px" }}
                            >
                              WHAT IS THE PRO VERSION?
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <h6 style={{ fontSize: "18px" }}>
                                With the pro version, you unlock premium
                                features in addition to the standard features
                                like –
                              </h6>
                              <ul>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Switching between different profiles{" "}
                                </li>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Varied profile themes
                                </li>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Custom Icons & Titles
                                </li>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Multiple social media profiles
                                </li>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  Media Gallery
                                </li>
                                <li
                                  style={{ fontSize: "18px", color: "black" }}
                                >
                                  {" "}
                                  Advanced Analytics{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              style={{ fontSize: "18px" }}
                            >
                              WHAT IS MY CONTACT?
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                My Contact is the list of all your connections
                                who have shared their information with you on
                                your Connct profile. You can download all the
                                contacts and integrate it with your CRM for
                                better conversion.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              style={{ fontSize: "18px" }}
                            >
                              HOW DO I SHARE MY INFO IF THEY DON'T HAVE NFC?
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                You can share your contact information by
                                scanning the QR code printed on your digital
                                visiting card or opening the QR code from the
                                app installed on your phone.
                                <br />
                                Alternatively, you can share your profile via
                                text, email, or any social media with a link.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFour">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapsefour"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              style={{ fontSize: "18px" }}
                            >
                              CAN I CHANGE MY DETAILS?
                            </button>
                          </h2>
                          <div
                            id="collapsefour"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                {" "}
                                Yes, you can change your details in real-time to
                                fit every Interaction. You can also enable and
                                disable any details that you want to share.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFive">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapsefive"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              style={{ fontSize: "18px" }}
                            >
                              IS MY DATA SECURE?
                            </button>
                          </h2>
                          <div
                            id="collapsefive"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p style={{ fontSize: "18px", color: "black" }}>
                                Built on advanced tech frameworks, Connct
                                prioritizes data security ensuring no
                                unauthorized access to your data. The NFC card
                                only works when you tap it on a compatible
                                device and shares only what you allow.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  {/* ----------faqs sidebar------- */}
                  <div className="sidebar">
                    <div class="contact px-4 py-4 mt-5">
                      <LazyLoadImage
                        src={require("../assets/images/map.png")}
                        alt="maps"
                      />
                      <h3>Contact Info</h3>
                      <span style={{ fontSize: "16px" }}>
                        Email : info@connct.ai <br />
                        Phone : +91 725 995 7875
                      </span>
                    </div>

                    <div className="contact px-4 py-4 my-4">
                      <LazyLoadImage
                        src={require("../assets/images/phone.png")}
                        alt="phones"
                      />
                      <h3>Address</h3>
                      <span style={{ fontSize: "16px" }}>
                        #206, 1st floor , Oxford towers , Kodihalli
                        Bangalore-560008.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Faq;
