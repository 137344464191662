import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleImage from "../assets/images/googler.png";
import { useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const emailError = useState("");
  // const passwordError = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [emailError, setEmailError] = useState(""); // Changed to useState for error state
  const [passwordError, setPasswordError] = useState(""); // Changed to useState for error state

  const navigate = useNavigate();
  const { state } = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  // let notifyStatus;
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validations
    let valid = true;

    // Email validation
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else {
      setEmailError(""); // Clear error if valid
    }

    // Password validation
    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else {
      setPasswordError(""); // Clear error if valid
    }

    if (!valid) {
      // If any validation failed, prevent API call
      return;
    }

    // Make the API request
    // notifyStatus = toast.loading("Processing...", {
    //   closeButton: "false",
    //   isLoading: "true",
    // });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/auth/signin`,
        {
          email,
          password,
        }
      );

      // Assuming the API returns a success status code (e.g., 200)
      if (response.status === 200) {
        // Redirect to the Home page
        // window.location.href = "/product-list"; // You can replace this with your actual Home page URL
        const authToken = response.data.token;

        // Store the token in localStorage
        localStorage.setItem("authToken", authToken);
        localStorage.setItem("authUser", email);

        // Display a success toast message
        const { fromCart, fromConfirmPassword, fromSignup } = state || {};
        if (fromCart) {
          navigate("/billing", { replace: true });
        } else if (fromConfirmPassword || fromSignup) {
          navigate("/");
        } else {
          navigate("/");
        }
        // toast.update(notifyStatus, {
        //   render: "Successfully signed in.",
        //   type: "success",
        //   closeButton: true,
        //   isLoading: false,
        //   autoClose: 1000,
        // });
        toast.success("Successfully signed in.");
      } else {
        // Handle other response statuses or errors from the API
        console.log("API request failed with status: " + response.status);

        if (response.status === 401) {
          // Unauthorized: Credentials do not match
          // Check the response data for specific error messages
          if (response.data && response.data.error === "EmailNotRegistered") {
            // toast.update(notifyStatus, {
            //   render: "Email is not registered.",
            //   type: "error",
            //   closeButton: true,
            //   isLoading: false,
            //   autoClose: 1000,
            // });
            toast.error("Email is not registered.");
          } else if (
            response.data &&
            response.data.message === "InvalidPassword"
          ) {
            // toast.update(notifyStatus, {
            //   render: "Email and password do not match.",
            //   type: "error",
            //   closeButton: true,
            //   isLoading: false,
            //   autoClose: 1000,
            // });
            toast.error("Email and password do not match.");
          } else {
            // toast.update(notifyStatus, {
            //   render: "Invalid email or password.",
            //   type: "error",
            //   closeButton: true,
            //   isLoading: false,
            //   autoClose: 1000,
            // });
            toast.error("Invalid email or password.");
          }
        } else {
          // Handle other API errors here
          // toast.update(notifyStatus, {
          //   render: "An error occurred while signing in.",
          //   type: "error",
          //   closeButton: true,
          //   isLoading: false,
          //   autoClose: 1000,
          // });
          toast.error("An error occurred while signing in.");
        }
      }
    } catch (error) {
      // Handle API request errors, e.g., network issues
      console.error("API request error", error);
      // toast.update(notifyStatus, {
      //   render: "Error! Unable to login, Please check credentials.",
      //   type: "error",
      //   closeButton: true,
      //   isLoading: false,
      //   autoClose: 2000,
      // });
      toast.error(error?.response?.data?.message);
    }
  };
  const verticalLineStyle = {
    borderLeft: "2px solid lightgray",
    height: "80%",
    position: "absolute",
    left: "-10px",
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/auth/socialsignin`,
        {
          email: userInfo.name,
          googleId: userInfo.sub,
          fullName: userInfo.name,
        }
      );
      if (response.status === 200) {
        localStorage.setItem("authToken", response?.data?.token);
        localStorage.setItem("authUser", response?.data?.user?.email);
        JSON.parse(localStorage.getItem("cart"));
        const { fromCart } = state || {};
        if (fromCart) {
          navigate("/billing", { replace: true });
        } else {
          navigate(-1);
        }
        // cartItems && cartItems?.length > 0
        //   ? navigate("/shoppingcart")
        //   : navigate("/");
      }
    },
  });

  return (
    <div className="wrapper" style={{ marginTop: "100px" }}>
      <nav className="navbar navbar-expand-lg fixed">
        <div className="container">
          <a className="navbar-brand" href="/">
            <LazyLoadImage
              className="header-logo img-fluid"
              src={require("../assets/images/logos.png")}
              alt="Logo"
              style={{ maxWidth: "150px" }}
            />
          </a>
          <button
            className={`navbar-toggler ${isMenuOpen ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/faq"
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    fontWeight: "normal", // Add this line
                  }}
                >
                  {!isMenuOpen && <span style={verticalLineStyle}></span>}
                  Need any help?
                </a>
              </li>
              {/* Add more menu items here */}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container">
        <section className="login">
          <div className="row align-items-center">
            <div className="col-sm-6 login-left d-none d-sm-block">
              <LazyLoadImage
                src={require("../assets/images/login.jpg")}
                className="img-fluid d-block mx-auto login-image"
                alt="Login"
                style={{ height: "500px" }}
              />
            </div>

            <div className="col-sm-6 login-right">
              <div className="login-account">
                <a href="/">
                  <LazyLoadImage
                    src={require("../assets/images/logo-icon.png")}
                    className="footer-logo d-block mx-auto mt-5"
                    alt="Logo Icon"
                    style={{ maxHeight: "100%", width: "auto" }}
                  />
                </a>
                <h2>Welcome back</h2>
                <h3>Kindly login to your account</h3>

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      id="email"
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ fontSize: "14px", height: "45px" }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "4px",
                        }}
                      >
                        {emailError}
                      </p>
                    )}
                  </div>
                  <div className="mb-3">
                    <input
                      id="password"
                      type="password"
                      className="form-control in2"
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ fontSize: "14px", height: "45px" }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordError && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "4px",
                        }}
                      >
                        {passwordError}
                      </p>
                    )}
                  </div>

                  <p className="d-flex">
                    <a
                      className="gray"
                      href="/forgot-password"
                      style={{ color: "gray", textDecoration: "none" }}
                    >
                      Forgot account? <span>/ Reset</span>
                    </a>
                  </p>

                  <button
                    id="login-button"
                    className="btn btn-primary"
                    type="submit"
                  >
                    Login
                  </button>

                  <p className="text-center">or</p>
                  <div className="d-flex justify-content-between btn-grp">
                    {/* <a
                      className="btn btn-secondary"
                      href="https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?redirect_uri=storagerelay%3A%2F%2Fhttps%2Fconnct.ai%3Fid%3Dauth20101&response_type=permission%20id_token&scope=email%20profile%20openid&openid.realm&include_granted_scopes=true&client_id=192194968702-u8hamnori5596uqsekd0ie4ktjcr2mkt.apps.googleusercontent.com&ss_domain=https%3A%2F%2Fconnct.ai&fetch_basic_profile=true&gsiwebsdk=2&service=lso&o2v=1&flowName=GeneralOAuthFlow"
                      id="myButton"
                      target="_blank" // Add this attribute to open in a new tab
                      rel="noreferrer"
                    >
                      <img src={GoogleImage} alt="Google" />
                      Google
                    </a> */}
                    <button
                      className="btn btn-secondary"
                      id="myButton"
                      onClick={(e) => {
                        e.preventDefault();
                        return login();
                      }}
                    >
                      <LazyLoadImage src={GoogleImage} alt="Google" />
                      Google
                    </button>

                    <a
                      id="my-custom-button"
                      href="https://www.facebook.com/login.php?skip_api_login=1&api_key=544002987140470&kid_directed_site=0&app_id=544002987140470&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv10.0%2Fdialog%2Foauth%3Fapp_id%3D544002987140470%26cbt%3D1694164282651%26channel_url%3Dhttps%253A%252F%252Fstaticxx.facebook.com%252Fx%252Fconnect%252Fxd_arbiter%252F%253Fversion%253D46%2523cb%253Df357b08d5251d54%2526domain%253Dconnct.ai%2526is_canvas%253Dfalse%2526origin%253Dhttps%25253A%25252F%25252Fconnct.ai%25252Ff245cded952540c%2526relation%253Dopener%26client_id%3D544002987140470%26display%3Dpopup%26domain%3Dconnct.ai%26e2e%3D%257B%257D%26fallback_redirect_uri%3Dhttps%253A%252F%252Fconnct.ai%252Flogin%26fields%3Dname%252Cemail%252Cpicture%252Cfirst_name%252Clast_name%26locale%3Den_US%26logger_id%3Df25f98b6f0442a8%26origin%3D1%26redirect_uri%3Dhttps%253A%252F%252Fstaticxx.facebook.com%252Fx%252Fconnect%252Fxd_arbiter%252F%253Fversion%253D46%2523cb%253Df18dfd1ff940cf4%2526domain%253Dconnct.ai%2526is_canvas%253Dfalse%2526origin%253Dhttps%25253A%25252F%25252Fconnct.ai%25252Ff245cded952540c%2526relation%253Dopener%2526frame%253Df1097ec6e56822%26response_type%3Dtoken%252Csigned_request%252Cgraph_domain%26scope%3Demail%252Cpublic_profile%26sdk%3Djoey%26version%3Dv10.0%26ret%3Dlogin%26fbapp_pres%3D0%26tp%3Dunspecified&cancel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df18dfd1ff940cf4%26domain%3Dconnct.ai%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fconnct.ai%252Ff245cded952540c%26relation%3Dopener%26frame%3Df1097ec6e56822%26error%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied&display=popup&locale=en_GB&pl_dbl=0"
                      target="_blank" // Add this attribute to open in a new tab
                      rel="noreferrer"
                    >
                      <LazyLoadImage
                        src={require("../assets/images/login-fb.png")}
                        alt="Facebook"
                      />{" "}
                      Facebook
                    </a>
                  </div>

                  <p className="d-flex acc">Don't have any account?</p>
                  <p className="d-flex signup">
                    <a className="black fw-bold" href="/sign-up">
                      Sign up
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const GoogleProvider = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY}>
      <Login />
    </GoogleOAuthProvider>
  );
};

export default GoogleProvider;
