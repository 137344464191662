import React, { useState } from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Contact.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Contact = () => {
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [contactForm, setContactForm] = useState({
    email: "",
    topic: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear email error when user enters a valid email
    if (name === "email" && value) {
      setEmailError("");
    }

    // Clear message error when user enters a message
    if (name === "message" && value) {
      setMessageError("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check for email and message errors
    let hasErrors = false;

    if (!contactForm.email) {
      setEmailError("Email address is required");
      hasErrors = true;
    } else {
      setEmailError("");
    }

    if (!contactForm.message) {
      setMessageError("Please write a message");
      hasErrors = true;
    } else {
      setMessageError("");
    }

    // If there are errors, do not proceed with the form submission
    if (hasErrors) {
      return;
    }

    // Prepare the data for the API request
    const requestData = {
      email: contactForm.email,
      topic: contactForm.topic,
      message: contactForm.message,
    };

    // Make the API request
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/api/public-api/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the API response data as needed
        console.log("API response:", data);

        // Display a success message using react-toastify
        toast.success(
          "Success! Thank you for getting in touch with us  One of our colleague will get back and touch with you soon ! Thank You"
        );

        // You can also reset the form or take other actions here
        // Reset the form values
        setContactForm({
          email: "",
          topic: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("API error:", error);
        // Handle the error (e.g., show an error message to the user)
      });
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Contact the Best Digital Business Card Provider | Connct</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="facebook-domain-verification"
          content="76mxtqr8maf4jd7publr59pn5stjur"
        ></meta>
        <meta
          name="title"
          content="Contact the Best Digital Business Card Provider | Connct"
        />
        <meta
          name="description"
          content="Contact Connct for effortless digital networking. Elevate connections with NFC-enabled business cards. Tap and go for seamless networking!"
        ></meta>
      </Helmet>
      <div className="wrapper" style={{ marginTop: "130px" }}>
        <section className="section contact">
          <div className="container">
            <div className="page-header">
              <h1 style={{ fontSize: "32px", fontWeight: "800" }}>
                We are known for our lightning-fast ⚡ support.
              </h1>
              <p style={{ fontSize: "16px" }}>
                Fill the form below or send us a message anywhere. We’ll get
                back ASAP!
              </p>
              <br />{" "}
              <hr className="lightgray-lines" style={{ marginTop: "3px" }} />
            </div>

            <section className="left" style={{ marginTop: "30px" }}>
              <div className="row">
                <div className="col-md-6 py-4">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="email" className="form-label">
                          Email Address
                        </label>
                        <input
                          id="email"
                          name="email"
                          value={contactForm.email}
                          onChange={handleInputChange}
                          className="form-control"
                          aria-label="Default select example"
                          type="text"
                          placeholder=""
                        />
                        {/* Display email error message */}
                        {emailError && (
                          <div className="text-danger">{emailError}</div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col py-4">
                        <label htmlFor="topic" className="form-label d-block">
                          Select Topic
                        </label>
                        <select
                          id="topic"
                          name="topic"
                          value={contactForm.topic}
                          onChange={handleInputChange}
                          className="form-select"
                          aria-label="Default select example"
                          style={{ fontSize: "14px" }}
                        >
                          {/* Specific topic options */}
                          <option
                            value="BusinessPartnership"
                            className="slanted-text"
                          >
                            Business Partnership
                          </option>
                          <option
                            value="BillingAndPlans"
                            className="slanted-text"
                          >
                            Billing and Plans
                          </option>
                          <option
                            value="TechnicalHelp"
                            className="slanted-text"
                          >
                            Technical Help
                          </option>
                          <option
                            value="ConnectAmbassador"
                            className="slanted-text"
                          >
                            Connect Ambassador
                          </option>
                        </select>

                        {/* Error messages */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <label htmlFor="message" className="form-label">
                          Message
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          value={contactForm.message}
                          onChange={handleInputChange}
                          className="form-control"
                          cols="30"
                          rows="5"
                        ></textarea>
                        {/* Display message error message */}
                        {messageError && (
                          <div className="text-danger">{messageError}</div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <button
                          type="submit"
                          className="btn btn-primary mt-4"
                          id="series"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Let’s Connect
                        </button>
                      </div>
                    </div>
                  </form>

                  <div className="row py-4">
                    <div className="col-sm-6 mb-4" data-aos="fade-up">
                      <div className="contact h-100">
                        <LazyLoadImage
                          src={require("../assets/images/map.png")}
                          alt="map"
                        />
                        <h3>Contact Info</h3>
                        <p>Email : info@connct.ai</p>
                        <p>Phone : +91 725 995 7875</p>
                      </div>
                    </div>

                    <div
                      className="col-sm-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div className="address h-100">
                        <LazyLoadImage
                          src={require("../assets/images/phone.png")}
                          alt="phone"
                        />
                        <h3>Address</h3>
                        <p>
                          #206, 1st floor, Oxford towers, Kodihallii Bangalore
                          -560008
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 right">
                  <LazyLoadImage
                    className="img-fluid mx-auto d-block w-100"
                    src={require("../assets/images/create-a-digital-business-card.png")}
                    alt="create a digital business card"
                  />
                </div>
              </div>
            </section>
          </div>
        </section>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Contact;
