import React from "react";
import { Container, Button } from "react-bootstrap";
import logoIcon from "../assets/images/logo-icon.svg";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ThankYou = () => {
  return (
    <Container style={{ marginTop: "120px" }}>
      <section className="section">
        <div className="row" style={{ margin: "60px 0" }}>
          <div className="col-md-12 text-center thanks">
            <LazyLoadImage
              src={logoIcon}
              alt="thankyou"
              className="thank-logo"
            />
            <h3 className="title mt-5" style={{ fontWeight: 700 }}>
              Thank you for your order.
            </h3>
            <p style={{ padding: "15px 0", fontSize: "16px" }}>
              Your order is complete. Please check your email for the details.
            </p>
            <Link to="/product-list" state={{ toPopular: true }}>
              <Button variant="outline-primary" size="lg">
                continue shopping
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default ThankYou;
